import { Component, OnInit, Sanitizer } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ScrollToService } from 'ng2-scroll-to-el';

@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {
  constructor(private sanitizer: DomSanitizer, private scrollService: ScrollToService) {
  }
  ngOnInit(): void {
  }
  replace(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  filterRedirect(id: any): void {
    sessionStorage.setItem('activity-redirect', id);
  }

  goToContact(): void {
    this.scrollService.scrollTo(`#contato`, 500, -75);
  }




}
