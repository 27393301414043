import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container';
import { MatButtonModule } from '@angular/material';
import { ListUnitsModule } from '../../shared/components/list-units/list-units.module';
import { ActivitiesModule } from '../../shared/components/activities/activities.module';
import { TrainersModule } from '../../shared/components/trainers/trainers.module';
import { ContactModule } from '../../shared/components/contact/contact.module';
import { MantraModule } from '../../shared/components/mantra/mantra.module';
import { Ae2SeoModule } from '@angularecommerce/core/directives/seo/seo.module';
import { Ae2InstructorListContainerModule } from '@angularecommerce/core/components/instructor-list-container';
import { GaleriaModule } from '../../shared/components/galeria/galeria.module';

@NgModule({
  imports: [
    CommonModule,
    HomeRoutingModule,
    MatButtonModule,
    Ae2CarouselContainerModule,
    ListUnitsModule,
    ActivitiesModule,
    TrainersModule,
    ContactModule,
    FlexLayoutModule,
    MantraModule,
    Ae2InstructorListContainerModule,
    GaleriaModule,
    Ae2SeoModule
  ],
  declarations: [HomeComponent]
})
export class HomeModule { }
