var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Ae2MarketingService, Ae2MarketingEmailsAcquired } from '@angularecommerce/core/services/marketing';
import { ae2EmailValidatorFn } from '@angularecommerce/core/functions/email-validator';
import { Ae2FeedbackService, Ae2FeedbackFormat } from '@angularecommerce/core/services/feedback';
import { Language } from 'angular-l10n';
var FooterComponent = /** @class */ (function () {
    function FooterComponent(marketingService, feedbackService) {
        this.marketingService = marketingService;
        this.feedbackService = feedbackService;
    }
    FooterComponent.prototype.ngOnInit = function () {
        this.data = new Date().getFullYear();
        this.form = this.createForm();
    };
    FooterComponent.prototype.submit = function () {
        var _this = this;
        if (this.form.get('email').value === '') {
            return;
        }
        var data = new Ae2MarketingEmailsAcquired();
        data.email = this.form.get('email').value;
        data.source = 'https://ecommerce2-dev.angular-ecommerce.com/';
        data.campaign = 'newslleter';
        this.marketingService.subscribeUser(data).subscribe(function (res) {
            if (res.email) {
                _this.form.reset();
                _this.feedbackService.success({
                    message: 'Newsletter assinada com sucesso',
                    format: Ae2FeedbackFormat.Notification,
                    duration: 2000
                });
            }
        });
    };
    FooterComponent.prototype.targetLint = function (link) {
        window.open(link, '_blank');
    };
    FooterComponent.prototype.goToTop = function () {
        // document.body.scrollTop = 0;
        // document.documentElement.scrollTop = 0;
        var scrollHeight = window.scrollY, scrollStep = Math.PI / (600 / 15), cosParameter = scrollHeight / 2;
        var scrollCount = 0, scrollMargin;
        var scrollInterval = setInterval(function () {
            if (window.scrollY !== 0) {
                scrollCount = scrollCount + 1;
                scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
                window.scrollTo(0, (scrollHeight - scrollMargin));
            }
            else {
                clearInterval(scrollInterval);
            }
        }, 15);
    };
    FooterComponent.prototype.createForm = function () {
        return new FormGroup({
            email: new FormControl('', [Validators.required, Validators.minLength(6), ae2EmailValidatorFn])
        });
    };
    __decorate([
        Language(),
        __metadata("design:type", String)
    ], FooterComponent.prototype, "lang", void 0);
    return FooterComponent;
}());
export { FooterComponent };
