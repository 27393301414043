import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule } from '@angular/core';
import { FooterComponent } from './footer.component';
import { Ae2FooterModule } from '@angularecommerce/core/components/footer';
import {
  Ae2SiteSettingsModule,
} from '@angularecommerce/core/components/site-settings';
import { Ae2UnitListContainerModule } from '@angularecommerce/core/components/unit-list-container';
import { LocalizationModule } from 'angular-l10n';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    Ae2FooterModule,
    FlexLayoutModule,
    Ae2SiteSettingsModule,
    Ae2UnitListContainerModule,
    LocalizationModule,
    RouterModule
  ],
  declarations: [FooterComponent],
  exports: [FooterComponent]
})
export class FooterModule { }
