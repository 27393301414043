import { NgModule } from '@angular/core';
import { WidgetEventComponent } from './widget-event.component';
import { MatCardModule, MatButtonModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';
import { Ae2DateTimeFormatPipeModule } from '@angularecommerce/core/pipes/datetime';
import { RouterModule } from '@angular/router';
import { Ae2EventWidgetModule } from '@angularecommerce/core/components/event-widget';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    Ae2ThumborModule,
    RouterModule,
    Ae2EventWidgetModule,
    Ae2DateTimeFormatPipeModule,
    MatButtonModule
  ],
  declarations: [WidgetEventComponent],
  exports: [WidgetEventComponent]
})
export class WidgetEventModule { }
