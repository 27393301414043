<div class="section" *ngIf="destaque">

    <div class="container-fullw" data-atual="2">
        <a (click)="navRight()" class="seta next js-ctrlinstrutor" data-vai="1"><i class="fa fa-angle-right"
                aria-hidden="true"></i></a>
        <a (click)="navLeft()" class="seta prev js-ctrlinstrutor" data-vai="-1"><i class="fa fa-angle-left"
                aria-hidden="true"></i></a>

        <div class="instr-destaque">
            <div class="inst-container ativo" id="inst1-foto" data-array="0"
                style.background-image="url({{destaque?.largeImage ? (destaque.largeImage | ae2Thumbor:'400x533') : '/assets/images/fallback-intrutors.jpg'}})">
                <div class="instr-names">
                    <h3 class="nome" *ngIf="destaque?.nickname == ''">{{ destaque?.firstName }} {{ destaque?.lastName }}
                    </h3>
                    <h3 class="nome" *ngIf="destaque?.nickname != ''">{{ destaque?.nickname }}</h3>
                    <div class="instr-names-tagline">{{destaque?.tagline}}</div>
                </div>
            </div>
        </div>

        <div class="instr-resto">
            <ul class="lista-instr" *ngIf="total != 0">
                <ng-container *ngFor="let inst of instrutores, let i = index">
                    <li class="instr-single" (click)="randonChange(inst)"
                        style.background-image="url({{inst?.largeImage ? (inst?.largeImage | ae2Thumbor:'400x533') : '/assets/images/fallback-intrutors.jpg'}})">
                        <div class="inst-color" id="inst2-fotoalt">
                            <h3 id="inst2-nome" *ngIf="inst?.nickname == ''">{{ inst?.firstName }} {{ inst?.lastName }}
                            </h3>
                            <h3 id="inst2-nome" *ngIf="inst?.nickname != ''">{{ inst?.nickname }}</h3>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>


        <div class="instr-info">
            <div class="instr-info-esq">
                <div id="textodestaque">
                    <i class="arrowDecorator">&#xf105;</i>
                    <p class="nameD" *ngIf="destaque.nickname == ''"><strong>{{ destaque?.firstName }}
                            {{ destaque?.lastName }}</strong></p>
                    <p class="nameD" *ngIf="destaque?.nickname != ''"><strong>{{ destaque?.nickname }}</strong></p>
                    <p class="textD" [innerHtml]="destaque?.shortBio"></p>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-container *ngIf="destaque">
    <!-- ************EVENTOS************ -->
    <div class="unit_container_item_events">
        <ae2-event-list-container sort="start_time" pageSize="3" nextEvents="true" [instructorList]="[destaque.id]"
            [useLazyLoading]="true" [useLoadAllPages]="false">
            <ng-template let-events="results" let-books="eventBooks" let-autoBooks="eventAutoBooks">
                <h2 *ngIf="events.length > 0" class="unit_container_item_events_title">PRÓXIMAS AULAS</h2>

                <div *ngIf="events.length > 0" fxLayout fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="center center"
                    class="event_widget_container">
                    <ng-container *ngFor="let event of events">
                        <ae2-event-widget [event]="event" [autoBooks]="autoBooks" [eventBooks]="books"
                            [eventTemplate]="defaultTemplate" class="unit_container_item_events_card">

                            <!-- ***************************     EVENT WIDGET     ********************************* -->
                            <ng-template #defaultTemplate let-data>
                                <ng-container *ngIf="data">
                                    <app-widget-event [data]="data"></app-widget-event>
                                </ng-container>
                            </ng-template>
                            <!-- ***************************     /EVENT WIDGET     ********************************* -->


                        </ae2-event-widget>
                    </ng-container>
                </div>


            </ng-template>
        </ae2-event-list-container>
    </div>
    <!-- ************/EVENTOS************ -->
</ng-container>