<ng-container *ngIf="data">
    <div class="ae2event-widget-container">
        <div class="ae2event-data-icons" fxFlex="17%" fxLayout="column">
            <ae2-event-status [data]="data"></ae2-event-status>
            <ae2-event-open-info-dialog [data]="data"></ae2-event-open-info-dialog>
        </div>
        <div fxFlex="87%" class="ae2event-info">
            <p class="event_time">{{ data?.event?.startTime | ae2DateTimeFormat:'DD/MM' }} às {{ data?.event?.startTime | ae2DateTimeFormat: "HH[:]mm" }} ({{ data?.event?.durationTime
                }}min)
            </p>

            <div class="full" fxLayout fxLayoutAlign="center center">
                <span fxFlex="100%" class="ae2event-activityName">{{ data?.event?.name }}</span>
            </div>


            <!-- <ng-container *ngIf="data?.event?.activityDetail">
                <p class="ae2event-activity_instructor font2">{{ data?.event?.activityDetail?.name }}</p>
            </ng-container> -->
            <ng-container *ngIf="data?.event?.instructorDetail">
                <p class="ae2event-activity_instructor">com {{ data?.event?.instructorDetail?.firstName }} {{ data?.event?.instructorDetail?.lastName }}</p>
            </ng-container>
            <ng-container *ngIf="data?.event?.roomDetail">
                <div class="divider"></div>
                <p class="ae2event-activity_instructor">sala {{ data?.event?.roomDetail?.name }}</p>
            </ng-container>

            <ae2-event-actions [data]="data">
                <ng-template let-context>
                    <div fxLayout class="ae2event-button">
                        <button fxFlex="100%" mat-raised-button [style.background]="data?.event?.activityDetail?.colorCode ? '#' + data?.event?.activityDetail?.colorCode : '#8b2999'" (click)="context.action()">{{ context.label }}</button>
                    </div>
                </ng-template>
            </ae2-event-actions>
        </div>
    </div>
</ng-container>
