<ng-container *ngIf="showHeader">
    <app-header></app-header>
</ng-container>

<div class="contentWebSite">
    <router-outlet></router-outlet>
</div>

<ng-container *ngIf="showFooter">
    <app-footer></app-footer>
</ng-container>