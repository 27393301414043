/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mantra.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/router";
import * as i8 from "@angularecommerce/core/pipes/replace/replace.pipe";
import * as i9 from "../../../../../node_modules/@angularecommerce/core/components/site-settings/site-settings.component.ngfactory";
import * as i10 from "@angularecommerce/core/components/site-settings/site-settings.component";
import * as i11 from "@angularecommerce/core/services/settings/settings.service";
import * as i12 from "./mantra.component";
var styles_MantraComponent = [i0.styles];
var RenderType_MantraComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MantraComponent, data: {} });
export { RenderType_MantraComponent as RenderType_MantraComponent };
function View_MantraComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "mantra-description"]], [[8, "innerHTML", 1]], null, null, null, null)), i1.ɵppd(1, 2), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "mantra-logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "button", [["class", "mantra-button"], ["mat-button", ""], ["routerLink", "/agenda"]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(5, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(6, 16384, null, 0, i7.RouterLink, [i7.Router, i7.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, 0, ["RESERVE SUA AULA"]))], function (_ck, _v) { var currVal_3 = "/agenda"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent, 0), ((_v.context.site == null) ? null : _v.context.site.description), "<br>")); _ck(_v, 0, 0, currVal_0); var currVal_1 = (i1.ɵnov(_v, 5).disabled || null); var currVal_2 = (i1.ɵnov(_v, 5)._animationMode === "NoopAnimations"); _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_MantraComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i8.Ae2ReplacePipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 4, "section", [["class", "mantra"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "ae2-site-settings", [], null, null, null, i9.View_Ae2SiteSettingsComponent_0, i9.RenderType_Ae2SiteSettingsComponent)), i1.ɵdid(3, 49152, null, 1, i10.Ae2SiteSettingsComponent, [i11.Ae2SettingsService], null, null), i1.ɵqud(335544320, 1, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[1, 2]], null, 0, null, View_MantraComponent_1))], null, null); }
export function View_MantraComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mantra", [], null, null, null, View_MantraComponent_0, RenderType_MantraComponent)), i1.ɵdid(1, 114688, null, 0, i12.MantraComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MantraComponentNgFactory = i1.ɵccf("app-mantra", i12.MantraComponent, View_MantraComponent_Host_0, {}, {}, []);
export { MantraComponentNgFactory as MantraComponentNgFactory };
