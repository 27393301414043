import {
    Ae2HeaderLinkToPage,
    Ae2HeaderLinkToSession,
    Ae2HeaderMenu,
    Ae2HeaderSubmenu,
    Ae2HeaderWidgetConfig
} from '@angularecommerce/core/components/header-widget';
import {
    Ae2SignUpStepAddress,
    Ae2SignUpStepFitness,
    Ae2SignUpStepProfile,
    Ae2SignUpStepSignup
} from '@angularecommerce/core/components/sign-up-steps/sign-up-stepper/sign-up-default-steps.model';
import { Ae2SignUpStepperConfig } from '@angularecommerce/core/components/sign-up-steps';
import { Component, OnInit } from '@angular/core';


export const CustomAe2SignupConfig = new Ae2SignUpStepperConfig([
    new Ae2SignUpStepSignup(),
    new Ae2SignUpStepProfile(),
    new Ae2SignUpStepAddress()
]);


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {


  headerConfig: Ae2HeaderWidgetConfig = new Ae2HeaderWidgetConfig();
  signupConfig: Ae2SignUpStepperConfig;

  constructor() {
  }

  ngOnInit(): void {
    this.createSignupConfig();

    this.headerConfig = new Ae2HeaderWidgetConfig();
    this.headerConfig.menuItems = new Ae2HeaderMenu();
    this.headerConfig.menuItems.items = [
      new Ae2HeaderSubmenu('Estúdio', [
        new Ae2HeaderLinkToSession('Sobre', 'estudio'),
        new Ae2HeaderLinkToSession('Atividades', 'atividades'),
        // new Ae2HeaderLinkToPage('Serviços', '/atividades/self-care/4'),
        new Ae2HeaderLinkToSession('Serviços', 'estudio'),
        new Ae2HeaderLinkToSession('Instrutores', 'instrutores'),
        new Ae2HeaderLinkToSession('Fale Conosco', 'contato'),
      ]),
      new Ae2HeaderLinkToPage('Comprar', '/pacotes'),
      new Ae2HeaderLinkToPage('Reservar aulas', '/agenda')
    ];

    this.headerConfig.backgroundColor = '#000';
  }

  createSignupConfig(): void {
    this.signupConfig = CustomAe2SignupConfig;
  }

}
