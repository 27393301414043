/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angularecommerce/core/components/header-widget/header-widget.component.ngfactory";
import * as i3 from "@angularecommerce/core/components/header-widget/header-widget.component";
import * as i4 from "@angular/router";
import * as i5 from "@ngrx/store";
import * as i6 from "ng2-scroll-to-el/scrollTo.service";
import * as i7 from "@angularecommerce/core/services/settings/settings.service";
import * as i8 from "@angularecommerce/core/services/sticky-overlay-manager/sticky-overlay-manager.service";
import * as i9 from "@angular/flex-layout";
import * as i10 from "../../../../../node_modules/@angularecommerce/core/components/header-widget/header-layout-placement/header-layout-placement.component.ngfactory";
import * as i11 from "@angularecommerce/core/components/header-widget/header-layout-placement/header-layout-placement.component";
import * as i12 from "./header.component";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "ae2-header-widget", [["showCredits", "true"]], null, [["window", "scroll"], ["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:resize" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onResize() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_Ae2HeaderWidgetComponent_0, i2.RenderType_Ae2HeaderWidgetComponent)), i1.ɵdid(1, 4440064, null, 10, i3.Ae2HeaderWidgetComponent, [i4.Router, i5.Store, i6.ScrollToService, i1.ChangeDetectorRef, i7.Ae2SettingsService, i8.Ae2StickyOverlayManagerService, i1.Renderer2, i9.ObservableMedia], { config: [0, "config"], showCredits: [1, "showCredits"], signupConfig: [2, "signupConfig"] }, null), i1.ɵqud(335544320, 1, { headerTemplate: 0 }), i1.ɵqud(335544320, 2, { headerLoginTemplate: 0 }), i1.ɵqud(335544320, 3, { headerLogoTemplate: 0 }), i1.ɵqud(335544320, 4, { headerMenuTemplate: 0 }), i1.ɵqud(335544320, 5, { headerSidenavTemplate: 0 }), i1.ɵqud(335544320, 6, { headerSocialTemplate: 0 }), i1.ɵqud(335544320, 7, { headerSubmenuTemplate: 0 }), i1.ɵqud(335544320, 8, { headerUnitSelectorTemplate: 0 }), i1.ɵqud(335544320, 9, { signupTopTemplate: 0 }), i1.ɵqud(335544320, 10, { signupBottomTemplate: 0 }), (_l()(), i1.ɵeld(12, 0, null, null, 1, "ae2-header-layout-placement-widget", [], [[2, "ae2-header-layout-placement-widget", null]], null, null, i10.View_Ae2HeaderLayoutPlacementWidgetComponent_0, i10.RenderType_Ae2HeaderLayoutPlacementWidgetComponent)), i1.ɵdid(13, 114688, null, 0, i11.Ae2HeaderLayoutPlacementWidgetComponent, [i5.Store, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.headerConfig; var currVal_1 = "true"; var currVal_2 = _co.signupConfig; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); _ck(_v, 13, 0); }, function (_ck, _v) { var currVal_3 = true; _ck(_v, 12, 0, currVal_3); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 114688, null, 0, i12.HeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i12.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
