<ae2-unit-list-container>
    <ng-template #unitListTemplate let-results="results">
        <aside class="unit" *ngIf="results.length > 0">
            <mat-accordion>

                <mat-expansion-panel *ngFor="let item of results; let i = index" [expanded]="i == 0" [disabled]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{item?.name}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="unit_container" fxLayout fxLayout.xs="column" fxLayout.sm="column">
                        <div class="unit_container_item" fxFlex="50%" fxFlex.xs="auto">
                                <ae2-carousel-container carouselCode="estudio-jiwa" [config]="carouselConfig"></ae2-carousel-container>
                            <!-- <div class="unit_container_item_image" *ngIf="item.largeImage" style.background-image="url({{ item.largeImage | ae2Thumbor:'700x'}})"></div>
                            <div class="unit_container_item_image" *ngIf="!item.largeImage" [ngStyle]="{'background': 'url(/assets/images/fallback.jpg)'}"></div> -->
                        </div>
                        <div class="unit_container_item description" fxFlex="50%" fxFlex.xs="auto">
                            <div class="unit_container_item_description" [innerHTML]="item.description"></div>

                            <!---->
                        </div>
                    </div>
                    
                    <!-- ************EVENTOS************ -->
                    <div class="unit_container_item_events">
                        <ae2-event-list-container sort="start_time" pageSize="3" nextEvents="true" [unitList]="[item.id]"
                            [useLazyLoading]="true" [useLoadAllPages]="false">
                            <ng-template let-events="results" let-books="eventBooks" let-autoBooks="eventAutoBooks">
                                <h2 *ngIf="events.length > 0" class="unit_container_item_events_title">PRÓXIMAS AULAS</h2>

                                <div *ngIf="events.length > 0" fxLayout fxLayoutAlign="space-between" fxLayoutAlign.xs="center center"
                                    class="event_widget_container">
                                    <ng-container *ngFor="let event of events">
                                        <ae2-event-widget [event]="event" [autoBooks]="autoBooks" [eventBooks]="books"
                                            [eventTemplate]="defaultTemplate" class="unit_container_item_events_card">

                                            <!-- ***************************     EVENT WIDGET     ********************************* -->
                                            <ng-template #defaultTemplate let-data>
                                                <ng-container *ngIf="data">
                                                    <app-widget-event [data]="data"></app-widget-event>
                                                </ng-container>
                                            </ng-template>
                                            <!-- ***************************     /EVENT WIDGET     ********************************* -->


                                        </ae2-event-widget>
                                    </ng-container>
                                </div>


                            </ng-template>
                        </ae2-event-list-container>
                    </div>
                    <!-- ************/EVENTOS************ -->


                    <!-- ************MAPA************ -->
                    <div class="map end-container" *ngIf="item.latitude && item.longitude" fxLayout fxLayout.xs="column">
                        <div fxFlex="35%" fxFlex.xs="auto" fxFlex.sm="auto" class="map-endereco">
                            <ae2-site-settings>
                                <ng-template ae2SiteSettingsContent let-site="site">
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <div class="addressContainer">
                                            <small>ENDEREÇO</small>
                                            <p>{{ site.address1 }} {{site.address1 ? ', '+site.address2 :
                                                site.address2}}
                                                <br>{{site.address3}} {{site.address3 ? ', '+site.address4 :
                                                site.address4}}
                                                <br>{{site.city}} - {{site.state}}
                                                <br> CEP {{ site.zipCode }}<br>
                                                {{item?.parkingDescription}}</p>
                                            <br>
                                        </div>
                                        <div class="addressContainer">
                                            <small>TELEFONES</small>
                                            <p>{{ site.phone }}
                                                <br>{{ site.mobile }}</p>
                                        </div>
                                    </div>
                                </ng-template>
                            </ae2-site-settings>
                        </div>

                        <!--[styles]="customStyle"-->
                        <div fxFlex="65%" fxFlex.xs="auto">
                            <agm-map [latitude]="item.latitude" [longitude]="item.longitude" [zoom]="zoom"
                                [scrollwheel]="false">
                                <agm-marker [latitude]="item.latitude" [longitude]="item.longitude">
                                </agm-marker>
                            </agm-map>
                        </div>
                    </div>
                    <!-- ************/MAPA************ -->


                </mat-expansion-panel>

            </mat-accordion>
        </aside>
    </ng-template>
</ae2-unit-list-container>