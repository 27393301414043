import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-galeria',
  templateUrl: './galeria.component.html',
  styleUrls: ['./galeria.component.scss']
})
export class GaleriaComponent implements OnInit {

  total: number = 0;
  destaque: any;
  instrutores: any;
  allInstructores: any;

  @Input('instructors')
  set instructors(value: any[]) {
    if (value && value.length) {
      this.allInstructores = value;
      this.destaque = value[0];
      this.instrutores = JSON.parse(JSON.stringify(value)).splice(1);
      this.total = value.length;
    }
  }

  constructor() {
  }

  ngOnInit(): any {
    window.scrollTo(0, 0);
    const is: any = JSON.parse(localStorage.getItem('instructorSelected'));
    if (is) {
      const newInstructor = this.allInstructores.filter(i => i.id === is.id);
      this.randonChange(newInstructor[0]);
    }
  }

  navRight(): any {
    this.instrutores.push(this.destaque);
    this.destaque = this.instrutores[0];
    this.instrutores.shift();
  }

  navLeft(): any {
    this.instrutores.unshift(this.destaque);
    this.destaque = this.instrutores[this.instrutores.length - 1];
    this.instrutores.pop();
  }

  randonChange(inst: any): any {
    this.instrutores.push(this.destaque);
    this.destaque = inst;
    const pos = this.instrutores.indexOf(inst);

    for (let aux = 0; aux < this.total; aux++) {
      if (aux >= pos) {
        this.instrutores[aux] = this.instrutores[aux + 1];
      }
    }
    this.instrutores.pop();
  }

}
