import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-widget-event',
  templateUrl: './widget-event.component.html',
  styleUrls: ['./widget-event.component.scss']
})
export class WidgetEventComponent implements OnInit {
  

  @Input() data: any;
  
  constructor() { }

  ngOnInit(): void {
  }

}
