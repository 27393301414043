<ae2-seo [title]="'Jiwa | Body & Mind - Centro de Bem Estar '" [metaDescription]="'O Jiwa Body&Mind é um estúdio-boutique dedicado ao bem-estar físico e mental. A grade de aulas inclui yoga, pilates, vídeo dance, barre fit e meditação, além massagens, cursos e atendimentos nutricionais.'"
  [metaRobots]="'index, follow'"></ae2-seo>


<ae2-carousel-container carouselCode="home" [config]="carouselConfig">
  <ng-template ae2CarouselOverlayContent let-title="title" let-description="description" let-ctaLabel="ctaLabel"
    let-ctaUrl="ctaUrl">
    <ng-container *ngIf="title">
      <div class="ae2Carousel__overlay-content-default">
        <div fxFlex="80%" class="m-top">
          <h1 class="ae2Carousel__overlay-content-default__title" [innerHTML]="title"></h1>
          <h1 class="ae2Carousel__overlay-content-default__title subtitle" [innerHTML]="description"></h1>
        </div>
        <div fxFlex="20%">
          <button class="ae2Carousel__overlay-content-default__button" color="accent" mat-raised-button
            [routerLink]="ctaUrl">{{ ctaLabel }}</button>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ae2-carousel-container>


<!--<app-newsletter [open]="open"></app-newsletter>-->
<app-mantra></app-mantra>

<app-activities id="atividades"></app-activities>

<app-list-units id="estudio"></app-list-units>

<div id="instrutores">
  <h2 class="instrutores-title">Nosso Time</h2>
  <ae2-instructor-list-container [active]="true" [showInInstructorsPage]="true">
    <ng-template #instructorListTemplate let-results="results">
      <app-galeria [instructors]="results"></app-galeria>
    </ng-template>
  </ae2-instructor-list-container>
</div>
<!-- <app-trainers id="instrutores"></app-trainers> -->

<!--<app-package id="pacote"></app-package>-->

<!-- <app-calendar-home id="agenda"></app-calendar-home> -->

<div id="avaliacoes" class="avaliacoes">
  <div class="avaliacoes_container">
    <aside class="avaliacao_header">
      <h1 class="avaliation_header_title">AVALIAÇÕES</h1>
    </aside>

    <div fxLayout class="logos-container" fxLayoutAlign="center center">
      <div fxFlex="33%" fxLayout="column" fxLayoutAlign="center center">
        <img class="avaliation_img avaliation_img1" src="/assets/images/home/facebook-avaliacao.png" alt="">
        <img class="avaliation_img avaliation_img2" src="/assets/images/home/stars-avaliacoes.png" alt="">
      </div>

      <div fxFlex="33%" fxLayout="column" fxLayoutAlign="center center">
        <img class="avaliation_img avaliation_img1" src="/assets/images/home/google-ava.png" alt="">
        <img class="avaliation_img avaliation_img2" src="/assets/images/home/stars-avaliacoes.png" alt="">
      </div>

      <div fxFlex="33%" fxLayout="column" fxLayoutAlign="center center">
        <img class="avaliation_img avaliation_img1" src="/assets/images/home/classpass.png" alt="">
        <img class="avaliation_img avaliation_img2" src="/assets/images/home/stars-avaliacoes.png" alt="">
      </div>

      <!--<div fxFlex="25%" fxLayout="column" fxLayoutAlign="center center">
                <img class="avaliation_img avaliation_img1" src="/assets/images/home/estudio-ava.png" alt="">
                <img class="avaliation_img avaliation_img2" src="/assets/images/home/stars-avaliacoes.png" alt="">
            </div>-->

    </div>
  </div>
</div>

<app-contact id="contato"></app-contact>
