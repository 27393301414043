import { Routes, Resolve } from '@angular/router';
import { Ae2AuthRouteGuardService } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { Ae2ActivitiesService, Ae2ActivitiesServiceGetAllQuery } from '@angularecommerce/core/services/activities';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
export var CALENDAR_RESOLVER_CACHE_KEY = 'calendar-filter-default-activity';
import { HomeComponent } from './features/home/home.component';
var CalendarResolver = /** @class */ (function () {
    function CalendarResolver(ae2ActivitiesService) {
        this.ae2ActivitiesService = ae2ActivitiesService;
    }
    CalendarResolver.prototype.resolve = function () {
        if (localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)) {
            return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)));
        }
        var query = new Ae2ActivitiesServiceGetAllQuery();
        query.active = true;
        query.use_as_filter = true;
        return this.ae2ActivitiesService.getAll(query)
            .getData$()
            .pipe(map(function (activities) { return activities.results[0]; }), tap(function (activity) { return localStorage.setItem(CALENDAR_RESOLVER_CACHE_KEY, JSON.stringify(activity)); }));
    };
    return CalendarResolver;
}());
export { CalendarResolver };
var ɵ0 = { reuse: true }, ɵ1 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ2 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ3 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ4 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ5 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ6 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ7 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ8 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ9 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ10 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ11 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ12 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ13 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ14 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ15 = { sreuse: true, omeProperty: AE2_ROUTER_CLASSES_DATA }, ɵ16 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ17 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }, ɵ18 = { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA };
var routes = [
    {
        path: '',
        data: ɵ0,
        component: HomeComponent,
    },
    {
        path: 'online/class',
        loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
    },
    {
        path: 'online/thank-you',
        loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
    },
    {
        path: 'online/already-connected',
        loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
    },
    {
        path: 'login',
        loadChildren: 'app/features/login/login.module#LoginModule',
        data: ɵ1
    },
    {
        path: 'categoria/:categoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ2
    },
    {
        path: 'categoria/:categoryId/subcategoria/:subcategoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ3
    },
    {
        path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
        loadChildren: 'app/features/products/products.module#ProductsModule',
        data: ɵ4
    },
    {
        path: 'minha-conta',
        canActivate: [Ae2AuthRouteGuardService],
        loadChildren: 'app/features/profile/profile.module#ProfileModule',
        data: ɵ5
    },
    {
        path: 'checkout',
        loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
        data: ɵ6
    },
    {
        path: 'agenda',
        loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
        // data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
        resolve: {
            defaultActivity: CalendarResolver
        }
    },
    {
        path: 'evento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ7
    },
    {
        path: 'atividades',
        loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
        data: ɵ8
    },
    {
        path: 'atividades/:slug/:id',
        loadChildren: 'app/features/activity/activity.module#ActivityModule',
        data: ɵ9
    },
    {
        path: 'atividades/tickets/:slug/:id',
        loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
        data: ɵ10
    },
    {
        path: 'atividades/:slugatividade/:slugevento/:token',
        loadChildren: 'app/features/event/event.module#EventModule',
        data: ɵ11
    },
    {
        path: 'instrutor/:slug/:id',
        loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
        data: ɵ12
    },
    {
        path: 'clientes/resetar-senha/:uidb/:token',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ13
    },
    {
        path: 'clientes/resetar-senha',
        loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
        data: ɵ14
    },
    {
        path: 'faq',
        loadChildren: 'app/features/faq/faq.module#FaqModule',
        data: ɵ15
    },
    {
        path: 'termos-e-condicoes',
        loadChildren: 'app/features/termos/termos.module#TermosModule',
        data: ɵ16
    },
    {
        path: 'politica-de-privacidade',
        loadChildren: 'app/features/policy/policy.module#PolicyModule',
        data: ɵ17
    },
    {
        path: 'pacotes',
        loadChildren: 'app/features/packages/packages.module#PackagesModule',
        data: ɵ18
    },
    { path: '**', pathMatch: 'full', redirectTo: '' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18 };
