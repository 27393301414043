var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Ae2MyAccountGlobalConfig, MenuInterface } from '@angularecommerce/core/components/my-account';
var MyAccountCustomConfig = /** @class */ (function (_super) {
    __extends(MyAccountCustomConfig, _super);
    function MyAccountCustomConfig() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.showFormPerformance = false;
        _this.menu = [
            { name: 'Meus dados', link: 'meus-dados', icon: 'account_circle' },
            { name: 'Alterar minha senha', link: 'alterar-senha', icon: 'lock' },
            { name: 'Meus endereços', link: 'meus-enderecos', icon: 'room' },
            { name: 'Cartões de Crédito', link: 'metodos-pagamento', icon: 'credit_card' },
            { name: 'Meus pedidos', link: 'meus-pedidos', icon: 'bookmark_border' },
            { name: 'Minhas assinaturas', link: 'minhas-assinaturas', icon: 'bookmark' },
            { name: 'Meus eventos', link: 'meus-eventos', icon: 'event' },
            { name: 'Meus créditos', link: 'meus-creditos', icon: 'account_balance' },
            { name: 'Compartilhamento de créditos', link: 'compartilhar-creditos', icon: 'share' },
        ];
        return _this;
    }
    return MyAccountCustomConfig;
}(Ae2MyAccountGlobalConfig));
export { MyAccountCustomConfig };
/**
  * Comente essa linha quando precisar desabilitar o service worker
  */
// import { Ae2SwService } from '@angularecommerce/core/services/sw';
/**
* Comentado pois o uso de service worker provoca erros na dashboard,
* por estar no mesmo domínio
*/
// import { ServiceWorkerModule } from '@angular/service-worker';
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
