import { NgModule, Injectable } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Resolve } from '@angular/router';
import { Ae2AuthRouteGuardService, Ae2AuthRouteGuardServiceModule } from '@angularecommerce/core/services/auth-route-guard';
import { AE2_ROUTER_CLASSES_DATA } from '@angularecommerce/core/directives/router-classes';
import { Ae2ActivityModel } from '@angularecommerce/core/services/activities/activities.model';
import {
  Ae2ActivitiesService, Ae2ActivityServiceModule,
  Ae2ActivitiesServiceGetAllQuery
} from '@angularecommerce/core/services/activities';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
export const CALENDAR_RESOLVER_CACHE_KEY = 'calendar-filter-default-activity';
import { HomeComponent } from './features/home/home.component';
import { HomeModule } from './features/home/home.module';

@Injectable()
export class CalendarResolver implements Resolve<any> {

  constructor(
    private ae2ActivitiesService: Ae2ActivitiesService
  ) { }

  resolve(): Observable<Ae2ActivityModel> {
    if (localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)) {
      return of(JSON.parse(localStorage.getItem(CALENDAR_RESOLVER_CACHE_KEY)));
    }

    const query = new Ae2ActivitiesServiceGetAllQuery();
    query.active = true;
    query.use_as_filter = true;

    return this.ae2ActivitiesService.getAll(query)
      .getData$()
      .pipe(
        map(activities => activities.results[0]),
        tap(activity => localStorage.setItem(CALENDAR_RESOLVER_CACHE_KEY, JSON.stringify(activity)))
      );
  }
}

const routes: Routes = [
  {
    path: '',
    data: { reuse: true },
    component: HomeComponent,
  },
  {
    path: 'online/class',
    loadChildren: 'app/features/online-class/online-class.module#OnlineClassModule'
  },

  {
    path: 'online/thank-you',
    loadChildren: 'app/features/online-class-thanks/online-class-thanks.module#OnlineClassThanksModule'
  },

  {
    path: 'online/already-connected',
    loadChildren: 'app/features/online-class-disconnected/online-class-disconnected.module#OnlineClassDisconnectedModule'
  },
  {
    path: 'login',
    loadChildren: 'app/features/login/login.module#LoginModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'categoria/:categoryId/subcategoria/:subcategoryId/subsubcategoria/:subsubcategoryId',
    loadChildren: 'app/features/products/products.module#ProductsModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'minha-conta',
    canActivate: [Ae2AuthRouteGuardService],
    loadChildren: 'app/features/profile/profile.module#ProfileModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'checkout',
    loadChildren: 'app/features/checkout/checkout.module#CheckoutModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'agenda',
    loadChildren: 'app/features/calendar/calendar.module#CalendarModule',
    // data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
    resolve: {
      defaultActivity: CalendarResolver
    }
  },
  {
    path: 'evento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades',
    loadChildren: 'app/features/list-activities/list-activities.module#ListActivitiesModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slug/:id',
    loadChildren: 'app/features/activity/activity.module#ActivityModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/tickets/:slug/:id',
    loadChildren: 'app/features/activity-ticket/activity-ticket.module#ActivityTicketModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'atividades/:slugatividade/:slugevento/:token',
    loadChildren: 'app/features/event/event.module#EventModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'instrutor/:slug/:id',
    loadChildren: 'app/features/instructor/instructor.module#InstructorModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha/:uidb/:token',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'clientes/resetar-senha',
    loadChildren: 'app/features/forgot/forgot.module#ForgotModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'faq',
    loadChildren: 'app/features/faq/faq.module#FaqModule',
    data: { sreuse: true, omeProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'termos-e-condicoes',
    loadChildren: 'app/features/termos/termos.module#TermosModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'politica-de-privacidade',
    loadChildren: 'app/features/policy/policy.module#PolicyModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  {
    path: 'pacotes',
    loadChildren: 'app/features/packages/packages.module#PackagesModule',
    data: { reuse: true, someProperty: AE2_ROUTER_CLASSES_DATA }
  },
  { path: '**', pathMatch: 'full', redirectTo: '' }
];


@NgModule({
  imports: [
    Ae2AuthRouteGuardServiceModule,
    HomeModule,
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule],
  providers: [
    CalendarResolver,
    Ae2ActivitiesService
  ]
})
export class AppRoutingModule { }
