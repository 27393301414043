<ae2-activity-list-container showInActivitiesPage="true" active="true" [tag]="'atividade'">
    <ng-template let-results="results">
        <section class="activies" *ngIf="results.length > 0">
            <!--<div class="activies_header">
                <h1 class="activies_header_title">Atividades</h1>
                <p class="sub_title">Confira abaixo as nossas atividades disponíveis e veja qual se adequa a sua necessidade.</p>
            </div>-->
            <div class="activies-header">
                <h1 class="activies-header_title">ATIVIDADES</h1>
            </div>

            <div class="activies_container">

                <div class="activies_container_cads" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="40px" fxLayoutGap.xs="15px">
                    <div class="activies_container_cads_mobileWidth" *ngFor="let activity of results" 
                        fxFlex="calc(33% - 40px)" fxFlex.xs="auto" fxFlex.sm="50%">

                        <mat-card class="activies_container_cads_card">
                            <div>
                                <ng-container *ngIf="activity?.largeImage">
                                    <img src="{{activity?.largeImage | ae2Thumbor:'940x'}}" alt="{{activity?.name}}">
                                </ng-container>
                                <ng-container *ngIf="!activity?.largeImage">
                                    <img src="/assets/images/fallback.jpg" alt="{{activity?.name}}">
                                </ng-container>
                            </div>
                            <!-- <div class="activies_container_cads_card_image" *ngIf="activity.mediumImage" style.background-image="url({{ activity.mediumImage | ae2Thumbor:'400x' }})"></div>
                            <div class="activies_container_cads_card_image" *ngIf="!activity.mediumImage" [ngStyle]="{'background': 'url(../assets/images/fallback.jpg)'}"></div> -->

                            <!--<mat-card-content class="activies_container_cads_card_content">
                                <h1 class="activies_container_cads_card_content_title">{{activity.name}}</h1>
                                <div class="activies_container_cads_card_content_description">{{activity.tagline}}</div>
                            </mat-card-content>-->

                            <mat-card-content class="activies_container_cads_card_Color">
                                <div class="activies_container_cads_card_Color_content" fxLayout="column" fxLayoutAlign="center center">
                                    <!-- <h1 class="hover-title">{{activity.name}}</h1> -->
                                    <p class="hover-title">{{activity.tagline}}</p>
                                    <hr class="hr-separator">
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <button class="hover_button" color="accent" mat-raised-button [routerLink]="['/atividades', activity.slug, activity.id]">SAIBA
                                            MAIS</button>
                                        <ng-container *ngIf="activity?.slug != 'self-care'">
                                            <button class="hover_button" color="accent" mat-raised-button [routerLink]="['/agenda']"
                                                (click)="filterRedirect(activity.id)">AGENDE UMA AULA</button>
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>
                </div>


            </div>

        </section>
    </ng-template>
</ae2-activity-list-container>
<ae2-activity-list-container showInActivitiesPage="true" active="true" [tag]="'servicos'">
    <ng-template let-results="results">
        <section class="activies" *ngIf="results.length > 0">
            <!--<div class="activies_header">
                <h1 class="activies_header_title">Atividades</h1>
                <p class="sub_title">Confira abaixo as nossas atividades disponíveis e veja qual se adequa a sua necessidade.</p>
            </div>-->
            <div class="activies-header">
                <h1 class="activies-header_title">SERVIÇOS</h1>
            </div>

            <div class="activies_container">

                <div class="activies_container_cads" fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="center center" fxLayoutGap="40px" fxLayoutGap.xs="15px">
                    <div class="activies_container_cads_mobileWidth" *ngFor="let activity of results" 
                        fxFlex="calc(33% - 40px)" fxFlex.xs="auto" fxFlex.sm="50%">

                        <mat-card class="activies_container_cads_card">
                            <div>
                                <ng-container *ngIf="activity?.largeImage">
                                    <img src="{{activity?.largeImage | ae2Thumbor:'940x'}}" alt="{{activity?.name}}">
                                </ng-container>
                                <ng-container *ngIf="!activity?.largeImage">
                                    <img src="/assets/images/fallback.jpg" alt="{{activity?.name}}">
                                </ng-container>
                            </div>
                            <!-- <div class="activies_container_cads_card_image" *ngIf="activity.mediumImage" style.background-image="url({{ activity.mediumImage | ae2Thumbor:'400x' }})"></div>
                            <div class="activies_container_cads_card_image" *ngIf="!activity.mediumImage" [ngStyle]="{'background': 'url(../assets/images/fallback.jpg)'}"></div> -->

                            <!--<mat-card-content class="activies_container_cads_card_content">
                                <h1 class="activies_container_cads_card_content_title">{{activity.name}}</h1>
                                <div class="activies_container_cads_card_content_description">{{activity.tagline}}</div>
                            </mat-card-content>-->

                            <mat-card-content class="activies_container_cads_card_Color">
                                <div class="activies_container_cads_card_Color_content" fxLayout="column" fxLayoutAlign="center center">
                                    <h1 class="hover-title">{{activity.name}}</h1>
                                    <p class="hover-title">{{activity.tagline}}</p>
                                    <hr class="hr-separator">
                                    <div fxLayout="column" fxLayoutAlign="center center">
                                        <button class="hover_button" color="accent" mat-raised-button [routerLink]="['/atividades', activity.slug, activity.id]">SAIBA
                                            MAIS</button>
                                        <ng-container *ngIf="activity?.slug != 'self-care'">
                                            <!-- <button class="hover_button" color="accent" mat-raised-button [routerLink]="['/agenda']"
                                                (click)="filterRedirect(activity.id)">AGENDE UMA SESSÃO</button> -->
                                            <button class="hover_button" color="accent" mat-raised-button
                                                (click)="goToContact()">AGENDE UMA SESSÃO</button>
                                        </ng-container>
                                    </div>
                                </div>
                            </mat-card-content>

                        </mat-card>
                    </div>
                </div>


            </div>

        </section>
    </ng-template>
</ae2-activity-list-container>