import { NgModule } from '@angular/core';
import { MantraComponent } from './mantra.component';
import { MatCardModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings/site-settings.module';
import { Ae2ReplacePipeModule } from '@angularecommerce/core/pipes/replace';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
    Ae2SiteSettingsModule,
    Ae2ReplacePipeModule,
    MatButtonModule,
    RouterModule
  ],
  declarations: [MantraComponent],
  exports: [MantraComponent]
})
export class MantraModule { }
