<section class="mantra">
    <ae2-site-settings>
        <ng-template ae2SiteSettingsContent let-site="site">
            <div class="mantra-description" [innerHTML]="site?.description | ae2Replace:'<br>'"></div>
            <div class="mantra-logo"></div>
            <div>
                <button class="mantra-button" mat-button routerLink="/agenda">RESERVE SUA AULA</button>
            </div>
        </ng-template>
    </ae2-site-settings>
</section>