<ae2-footer>

    <ng-template #defaultTemplate let-settings>

        <!--<div fxLayout fxLayout.xs="column" fxLayoutAlign.xs="center" class="above_footer">
            <div fxFlex="33%" class="above_footer_block">
                <h1 class="sub_text">CORPO, MENTE E ALMA</h1>
            </div>
            <div fxFlex="33%" class="logo_above_footer">
                <img src="/assets/images/logo_footer.png">
            </div>
            <div fxFlex="33%" class="redes">
                <div class="redes_container">
                    <ae2-footer-follow></ae2-footer-follow>
                </div>
            </div>
        </div>-->



        <footer class="ae2-footer">

            <div class="ae2-footer_top">
                <div class="ae2-footer-session">

                    <div fxLayout="column" fxLayoutAlign="center center">
                        <div class="above_footer_block">
                            <h1 class="sub_text">CORPO, MENTE E ALMA</h1>
                        </div>
                        <div class="logo_above_footer">
                            <img src="/assets/images/jiwa.svg">
                        </div>
                        <div class="redes">
                            <div class="redes_container">
                                <ae2-footer-follow></ae2-footer-follow>
                            </div>
                        </div>
                    </div>

                    <!--<p class="first_text">Lorem ipsum nunc ad dolor ligula velit massa amet, placerat arcu nibh lacus neque metus senectus </p>-->
                </div>
                <div class="ae2-footer-session">
                    <!-- <ae2-footer-links></ae2-footer-links> -->
                    <a class="footer_link_item" routerLink="/faq">
                        {{ 'core.footer.labels.faq' | translate:lang }}
                    </a>
                    <a class="footer_link_item" routerLink="/politica-de-privacidade">
                        {{ 'core.footer.labels.privacyPolicy' | translate:lang }}
                    </a>
                    <a class="footer_link_item" target="_blank" href="https://static.angulare.app/u/jiwa/terms-and-conditions/termos-jiwa.pdf">
                        {{ 'core.footer.labels.terms' | translate:lang }}
                    </a>
                </div>

                <div class="ae2-footer-session">
                    <ae2-unit-list-container>
                        <ng-template #unitListTemplate let-results="results">
                            <ng-container *ngFor="let item of results">
                                    <div fxLayout class="footer-address_row">
                                        <div fxFlex="25%" class="footer-address_label">CNPJ</div>
                                        <div fxFlex="75%" class="footer-address_value">{{item?.cnpj}}</div>
                                    </div>
                            </ng-container>
                        </ng-template>
                    </ae2-unit-list-container>
                    <ae2-footer-address></ae2-footer-address>
                </div>
                <div class="ae2-footer-session">
                    <!-- <ae2-footer-newsletter></ae2-footer-newsletter> -->
                    <ae2-unit-list-container>
                        <ng-template #unitListTemplate let-results="results">
                            <ng-container *ngFor="let item of results">
                                <div>
                                    <div><span class="aceitamos">Horários de Funcionamento:</span></div>
                                    <div><span class="aceitamosHour">Segunda a sexta: {{item?.workingTimeWeek}}</span>
                                    </div>
                                    <div><span class="aceitamosHour">Sábado: {{item?.workingTimeSaturday}}</span></div>
                                    <div><span class="aceitamosHour">Domingos e feriados:
                                            {{item?.workingTimeSunday}}</span></div>
                                </div>
                            </ng-container>
                        </ng-template>
                    </ae2-unit-list-container>
                    <span class="aceitamos">Aceitamos</span>
                    <ae2-footer-payments></ae2-footer-payments>
                </div>
            </div>
            <div class="ae2-footer_bottom">
                <span>Copyright © 2016-{{currentYear}}. Todos os direitos reservados</span>
                <span>
                    <a href="https://angulare.app/?utm_source=cws&utm_campaign=jiwa" target="_blank" class="ae2-footer_bottom_powered">Powered by
                        Angular e-Commerce</a>
                </span>
            </div>
        </footer>
    </ng-template>


</ae2-footer>
