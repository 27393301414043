import { NgModule } from '@angular/core';
import { ListUnitsComponent } from './list-units.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { Ae2UnitListContainerModule } from '@angularecommerce/core/components/unit-list-container';
import { Ae2EventWidgetModule } from '@angularecommerce/core/components/event-widget';
import { Ae2EventListContainerModule } from '@angularecommerce/core/components/event-list-container';
import { Ae2StaffEditModule } from '@angularecommerce/core/components/staff-edit';
import { Ae2ThumborModule } from '@angularecommerce/core/pipes/thumbor';
import {MatExpansionModule} from '@angular/material/expansion';
import { AgmCoreModule } from '@agm/core';
import { MatButtonModule } from '@angular/material';
import { WidgetEventModule } from '../widget-event/widget-event.module';
import { Ae2SiteSettingsModule } from '@angularecommerce/core/components/site-settings/site-settings.module';
import { Ae2CarouselContainerModule } from '@angularecommerce/core/components/carousel-container/carousel-container.module'

@NgModule({
  imports: [
    CommonModule,
    MatExpansionModule,
    FlexLayoutModule,
    RouterModule,
    Ae2UnitListContainerModule,
    Ae2EventWidgetModule,
    Ae2EventListContainerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC4BTddSv9GGwsSvHSQnGTY_DLxGqMUfiU'
    }),
    Ae2StaffEditModule,
    MatButtonModule,
    Ae2ThumborModule,
    WidgetEventModule,
    Ae2SiteSettingsModule,
    Ae2CarouselContainerModule
  ],
  declarations: [ListUnitsComponent],
  exports: [ListUnitsComponent]
})
export class ListUnitsModule { }
