import { OnInit } from '@angular/core';
var GaleriaComponent = /** @class */ (function () {
    function GaleriaComponent() {
        this.total = 0;
    }
    Object.defineProperty(GaleriaComponent.prototype, "instructors", {
        set: function (value) {
            if (value && value.length) {
                this.allInstructores = value;
                this.destaque = value[0];
                this.instrutores = JSON.parse(JSON.stringify(value)).splice(1);
                this.total = value.length;
            }
        },
        enumerable: true,
        configurable: true
    });
    GaleriaComponent.prototype.ngOnInit = function () {
        window.scrollTo(0, 0);
        var is = JSON.parse(localStorage.getItem('instructorSelected'));
        if (is) {
            var newInstructor = this.allInstructores.filter(function (i) { return i.id === is.id; });
            this.randonChange(newInstructor[0]);
        }
    };
    GaleriaComponent.prototype.navRight = function () {
        this.instrutores.push(this.destaque);
        this.destaque = this.instrutores[0];
        this.instrutores.shift();
    };
    GaleriaComponent.prototype.navLeft = function () {
        this.instrutores.unshift(this.destaque);
        this.destaque = this.instrutores[this.instrutores.length - 1];
        this.instrutores.pop();
    };
    GaleriaComponent.prototype.randonChange = function (inst) {
        this.instrutores.push(this.destaque);
        this.destaque = inst;
        var pos = this.instrutores.indexOf(inst);
        for (var aux = 0; aux < this.total; aux++) {
            if (aux >= pos) {
                this.instrutores[aux] = this.instrutores[aux + 1];
            }
        }
        this.instrutores.pop();
    };
    return GaleriaComponent;
}());
export { GaleriaComponent };
